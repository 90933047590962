import AlgoliaInstantSearch from '../AlgoliaInstantSearch'
import CardListing from '../CardListing'
import TemplateFullWidth from '../TemplateFullWidth'
import Background from './background@2x.png'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Index, connectInfiniteHits, Configure } from 'react-instantsearch-dom'

export default function PageSearch(props) {
  const { site } = props.data
  const searchParams = new URLSearchParams(props.location.search)

  console.log('CATEGORY', searchParams.get('c'))

  return (
    <TemplateFullWidth>
      <Helmet>
        <title>Search {site.siteMetadata.title}</title>
      </Helmet>

      <div className={style.pageWrapper}>
        <div className={style.filters}>
          <form>
            <div className={style.inputSection}>
              <div className={style.heading}>
                <h5>Accomodation</h5>
                <a href='#'>Clear filters</a>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='home'
                  name='home'
                  value='Home'
                ></input>
                <label htmlFor='home'>Home</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='apartment'
                  name='apartment'
                  value='Apartment'
                ></input>
                <label htmlFor='apartment'>Apartment</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='townhome'
                  name='townhome'
                  value='Townhome'
                ></input>
                <label htmlFor='townhome'>Townhome</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='business'
                  name='business'
                  value='Business'
                ></input>
                <label htmlFor='business'>Business</label>
              </div>
            </div>
            <div className={style.inputSection}>
              <h5>Dog(s)</h5>
              <div className={style.radios}>
                <div className={style.inputGroup}>
                  <input type='radio' id='one' name='dogs' value='one' />
                  <label htmlFor='one'>1</label>
                </div>
                <div className={style.inputGroup}>
                  <input type='radio' id='two' name='dogs' value='two' />
                  <label htmlFor='two'>2</label>
                </div>
                <div className={style.inputGroup}>
                  <input type='radio' id='three' name='dogs' value='three' />
                  <label htmlFor='three'>3</label>
                </div>
                <div className={style.inputGroup}>
                  <input type='radio' id='four' name='dogs' value='four' />
                  <label htmlFor='four'>4</label>
                </div>
              </div>
            </div>
            <div className={style.inputSection}>
              <h5>Dog size</h5>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='small'
                  name='small'
                  value='Small'
                ></input>
                <label htmlFor='small'>Small (up to 15lbs)</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='medium'
                  name='medium'
                  value='Medium'
                ></input>
                <label htmlFor='medium'>Medium (16-40lbs)</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='large'
                  name='large'
                  value='Large'
                ></input>
                <label htmlFor='large'>Large (41-100lbs)</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='giant'
                  name='giant'
                  value='Giant'
                ></input>
                <label htmlFor='giant'>Giant (over 100 lbs)</label>
              </div>
            </div>
            <div className={style.inputSection}>
              <h5>Pricing</h5>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='price1'
                  name='price1'
                  value='Price 1'
                ></input>
                <label htmlFor='pricing'>$</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='price2'
                  name='price2'
                  value='Price 2'
                ></input>
                <label htmlFor='price2'>$$</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='price3'
                  name='price3'
                  value='Price 3'
                ></input>
                <label htmlFor='price3'>$$$</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='price4'
                  name='price4'
                  value='Price 4'
                ></input>
                <label htmlFor='price4'>$$$$</label>
              </div>
            </div>
            <div className={style.inputSection}>
              <h5>Additional criteria</h5>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='walk'
                  name='walk'
                  value='Walk'
                ></input>
                <label htmlFor='walk'>Outdoor walks</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='injection'
                  name='injection'
                  value='Injection'
                ></input>
                <label htmlFor='injection'>Injected medication</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='rawdiet'
                  name='rawdiet'
                  value='Raw diet'
                ></input>
                <label htmlFor='rawdiet'>Raw food storage</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='specialdiet'
                  name='specialdiet'
                  value='Special diet'
                ></input>
                <label htmlFor='specialdiet'>Specialty diet</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='reactive'
                  name='reactive'
                  value='Reactive'
                ></input>
                <label htmlFor='reactive'>Reactive dogs</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='behavior'
                  name='behavior'
                  value='Behavior concern'
                ></input>
                <label htmlFor='behavior'>Behavior concerns</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='senior'
                  name='senior'
                  value='Senior'
                ></input>
                <label htmlFor='senior'>Senior dog</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='puppy'
                  name='puppy'
                  value='Puppy'
                ></input>
                <label htmlFor='puppy'>Puppy</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='intact'
                  name='intact'
                  value='Intact'
                ></input>
                <label htmlFor='intact'>Non-neutered male</label>
              </div>
              <div className={style.inputGroup}>
                <input
                  type='checkbox'
                  id='unspayed'
                  name='unspayed'
                  value='Unspayed'
                ></input>
                <label htmlFor='unspayed'>Unspayed female</label>
              </div>
            </div>
          </form>
        </div>

        <div className={style.searchResults}>
          <AlgoliaInstantSearch placeholder={'Search'} />

          <Configure
            getRankingInfo='true'
            aroundLatLng={
              (searchParams.get('l') && searchParams.get('l')) || undefined
            }
            facetFilters={
              searchParams.get('c') && [`categories:${searchParams.get('c')}`]
            }
            removeWordsIfNoResults='allOptional'
            hitsPerPage={10}
          />

          <Index indexName='Listings'>
            <ListingIndex />
          </Index>
        </div>
        <div className={style.map}>
          <img
            src='https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=800&height=800&center=lonlat:-115.120216,36.201946&zoom=13&scaleFactor=2&apiKey=cd84c6383d8449f4bc7389926b627c6c
'
          ></img>
        </div>
      </div>
    </TemplateFullWidth>
  )
}

const Listings = ({ hits, hasMore, refineNext }) =>
  hits.length ? (
    <>
      <h1 className={style.h1}>Search results</h1>

      <div className={style.listings}>
        {hits.map((hit) => (
          <CardListing
            alt=''
            key={hit.id}
            link={{
              pathname: `/${hit.id}/`,
            }}
            image={hit.image}
            date={hit.date}
            listing={hit}
            services={hit.services}
            city={hit.city}
            title={hit.post_title}
          />
        ))}

        {hasMore && (
          <button className={style.button} onClick={() => refineNext()}>
            Load More Results
          </button>
        )}
      </div>
    </>
  ) : (
    <div className={style.noResults}>
      <div className={style.addBusiness}>
        <img src={Background}></img>
        <div className={style.addBusinessContent}>
          <div className={style.addBusinessCopy}>
            <h2>We can't find any results in this city</h2>
            <p>
              If you provide dog sitting services, you can apply to join
              doggos.com <Link to={'/add-business'}>here</Link>.
            </p>
            <Link className={style.button} to={'/add-business'}>
              Add a business
            </Link>
          </div>
        </div>
        <form></form>
      </div>
    </div>
  )
const ListingIndex = connectInfiniteHits(Listings)
